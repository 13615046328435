// import SanityBlockContent from "@sanity/block-content-to-react"
import React from "react"
// import { HeartIcon } from "@heroicons/react/outline"
// import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { Booking, DescCard, ImgCard, HeadCard } from "../components/template"
import Seo from "../components/seo"
export default function Page({ pageContext: { data } }) {
  // console.log(data)
  return (
    <Layout>
      <Seo
        title={data.title || data.name}
        // schemaMarkup={schema}
        // description={property.highlight}
      />
      <HeadCard data={data} />
      <ImgCard property={data} />
      <DescCard data={data} />
      <Booking
        single
        image={data.image.asset.gatsbyImageData.images.fallback.src}
        link="/actions/service/request?name="
        name={data.title || data.name}
      />
    </Layout>
  )
}
